<template>
  <div>
    <b-card-body>
      <b-row class="justify-content-between">
        <b-col cols="12" md="2">
          <Select
            v-model="search.is_attend"
            @on-change="SearchData"
            :clearable="true"
            placeholder="Select Attendance"
          >
            <Option :value="1"> Attend</Option>
            <Option :value="2"> Not Attend</Option>
          </Select>
        </b-col>
        <b-col cols="12" md="2">
          <Select
            v-model="search.is_cancel"
            @on-change="SearchData"
            :clearable="true"
            placeholder="Meeting status"
          >
            <Option :value="1"> Active</Option>
            <Option :value="2"> Canceled</Option>
          </Select>
        </b-col>
        <b-col cols="12" md="2">
          <Select
            v-model="search.select_status"
            @on-change="SearchData"
            :clearable="true"
            placeholder="Candidate status"
          >
            <Option :value="1"> Pending</Option>
            <Option :value="2"> Short listed</Option>
            <Option :value="3"> Interviewing</Option>
            <Option :value="4"> Selected</Option>
            <Option :value="5"> Rejected</Option>
            <Option :value="6"> Cancel interview</Option>
          </Select>
        </b-col>
        <b-col cols="12" md="2">
          <Select
            v-model="search.schedule_status"
            @on-change="SearchData"
            :clearable="true"
            placeholder="Meeting schedule"
          >
            <Option :value="1"> Today</Option>
            <Option :value="2"> Past</Option>
            <Option :value="3"> Upcoming</Option>
          </Select>
        </b-col>
        <b-col cols="12" md="3">
          <Input
            v-model="search.search_data"
            class="d-inline-block mr-1"
            placeholder="Search , name , email, phone..."
            type="text"
          />
        </b-col>
        <b-col
          cols="12"
          md="1"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label class="mr-1">Show</label>
          <Select
            @on-change="paginateChange"
            v-model="search.paginate"
            :clearable="false"
          >
            <Option :value="10"> 10</Option>
            <Option :value="30"> 30</Option>
            <Option :value="50"> 50</Option>
            <Option :value="80"> 80</Option>
            <Option :value="100"> 100</Option>
          </Select>
        </b-col>
      </b-row>
    </b-card-body>
    <b-card-text>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th class="text-center">Sl</th>
              <th>Company</th>
              <th>Name</th>
              <th>Job details</th>
              <th>Schedule Summary</th>
              <th>Schedule</th>
              <th>Status</th>
              <th class="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-if="schedules.data.length"
              v-for="(schedule, index) in schedules.data"
              :key="index"
            >
              <td class="align-middle text-center">
                {{ schedules.meta.from + index }}
              </td>

              <td>
                <img :src="schedule.company_logo" alt="dd" height="70" />
                <p>
                  <b>{{ schedule.company_name }}</b>
                </p>
              </td>

              <td>
                <p>{{ schedule.name }}</p>
                <p><b>Email: </b>{{ schedule.email }}</p>
                <p><b>Phone: </b>{{ schedule.phone }}</p>
              </td>

              <td>
                <p>
                  <b>{{ schedule.company_job_title }}</b>
                </p>
                <p><b>Commitment: </b>{{ schedule.company_job_commitment }}</p>
                <p>
                  <b>Workplace: </b>{{ schedule.company_job_workplace_type }}
                </p>
              </td>
              <td>
                <p><b>Summary: </b>{{ schedule.summary }}</p>
                <p><b>Location: </b>{{ schedule.location }}</p>
                <p><b>Description: </b>{{ schedule.description }}</p>
              </td>
              <td>
                <p><b>Start time: </b>{{ schedule.start_time }}</p>
                <p><b>End time: </b>{{ schedule.end_time }}</p>
                <p><b>Meeting link: </b>{{ schedule.meet_link }}</p>
              </td>
              <td>
                <p>
                  <b>Interview: </b>
                  <span
                    :class="
                      schedule.is_cancel === 'Interview Cancel'
                        ? 'badge badge-danger'
                        : 'badge badge-success'
                    "
                  >
                    {{
                      schedule.is_cancel === "Interview Cancel"
                        ? "Canceled"
                        : "Active"
                    }}
                  </span>
                </p>
                <p style="margin: 5px 0">
                  <b>Attendance: </b
                  ><span
                    :class="
                      schedule.is_attend !== 'Attend'
                        ? 'badge badge-danger'
                        : 'badge badge-success'
                    "
                  >
                    {{
                      schedule.is_attend !== "Attend" ? "Canceled" : "Active"
                    }}
                  </span>
                </p>
                <p>
                  <b>Status: </b>
                  <span
                    :class="
                      badgeClass(schedule.proposed_candidate_select_status)
                    "
                    >{{ schedule.proposed_candidate_select_status }}</span
                  >
                </p>
              </td>
              <td style="width: 200px">
                <Tooltip content="edit" placement="top-end">
                  <b-button
                    @click="scheduleEdit(schedule.id)"
                    variant="primary"
                    size="sm"
                    style="margin: 2px"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                </Tooltip>

                <Tooltip content="Cancel">
                  <b-button
                    @click="
                      showConfirmation(
                        schedule.proposed_candidate_id,
                        schedule.company_id,
                        schedule.id
                      )
                    "
                    variant="danger"
                    size="sm"
                    style="margin: 2px"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </Tooltip>
                <Tooltip content="Attendance" placement="top-end">
                  <b-button
                    @click="attendanceUpdate(schedule.id)"
                    :variant="
                      schedule.is_attend == 'Attend' ? 'warning' : 'primary'
                    "
                    size="sm"
                    style="margin: 2px"
                  >
                    <feather-icon
                      :icon="
                        schedule.is_attend == 'Attend' ? 'XIcon' : 'CheckIcon'
                      "
                    />
                  </b-button>
                </Tooltip>
              </td>
            </tr>
            <tr v-if="!schedules.data.length">
              <td class="text-center" colspan="6">
                <h4>No data found!</h4>
              </td>
            </tr>
          </tbody>
        </table>

        <b-modal v-model="modalShow" title="Update Schedule" hide-footer>
          <b-form @submit.prevent="updateSchedule(scheduleInfo.id)">
            <b-form-group label="Starting Date" label-for="starting_date">
              <b-form-input
                id="starting_date"
                v-model="scheduleInfo.starting_date"
                type="date"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Schedule Time">
              <b-form-input
                id="starting_time"
                v-model="scheduleInfo.starting_time"
                type="time"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Summary" label-for="summary">
              <b-form-input
                id="summary"
                v-model="scheduleInfo.summary"
                placeholder="Enter summary"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Location" label-for="location">
              <b-form-input
                id="location"
                v-model="scheduleInfo.location"
                placeholder="Enter location"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Description" label-for="description">
              <b-form-textarea
                id="textarea-rows"
                v-model="scheduleInfo.description"
                placeholder="Write a description about the event"
                rows="4"
              ></b-form-textarea>
            </b-form-group>

            <div class="text-right">
              <b-button
                type="submit"
                variant="primary"
                :disabled="scheduleInfo.busy"
                >Update</b-button
              >
            </div>
          </b-form>
        </b-modal>
      </div>
      <b-card-body>
        <div class="d-flex justify-content-between align-items-center">
          <span class="text-muted"
            >Showing {{ schedules.meta.from }} to {{ schedules.meta.to }} of
            {{ schedules.meta.total }} entries</span
          >
          <pagination
            :data="schedules.meta"
            @pagination-change-page="getResults"
            align="right"
            :show-disabled="true"
            :limit="5"
          ></pagination>
        </div>
      </b-card-body>
    </b-card-text>
  </div>
</template>

<script>
import {
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BCardBody,
  BFormTextarea,
} from "bootstrap-vue";
import _ from "lodash";

export default {
  data() {
    return {
      form: new Form({
        id: null,
        name: null,
        status: true,
      }),
      scheduleInfo: new Form({
        id: "",
        proposeId: "",
        companyId: "",
        starting_date: "",
        starting_time: "",
        summary: "",
        location: "",
        description: "",
      }),
      search: {
        search_data: "",
        status: "",
        is_attend: "",
        is_cancel: "",
        select_status: "",
        schedule_status: "",
        company_id: this.$route.query.company_id || "",
        company_job_id:
          this.$route.query.company_job_id || this.$route.params.id || "",
        candidate_id: this.$route.query.candidate_id || "",
        paginate: 10,
        page: 1,
      },
      attendanceForm: new Form({
        is_attend: true,
      }),
      schedules: {},
      modalShow: false,
    };
  },
  components: {
    BFormTextarea,
    BCardBody,
    BCard,
    BMedia,
    BFormFile,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BMediaAside,
    BMediaBody,
  },
  mounted() {
    this.getResults();
  },
  methods: {
    getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const queryParams = this.buildQueryParamString(this.search);
      axios
        .get(`/app/interview-schedule${queryParams}`)
        .then((res) => {
          this.schedules = res.data;
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

    scheduleEdit(scheduleId) {
      axios
        .get(`/app/get-interview-schedule/` + scheduleId + "/edit")
        .then((res) => {
          this.scheduleInfo.id = res.data.schedule.id;
          this.scheduleInfo.proposeId = res.data.schedule.proposed_candidate_id;
          this.scheduleInfo.companyId = res.data.schedule.company_id;
          const startTime = new Date(res.data.schedule.start_time);

          // Set date part
          this.scheduleInfo.starting_date = startTime
            .toISOString()
            .split("T")[0];

          // Set time part
          const hours = String(startTime.getHours()).padStart(2, "0");
          const minutes = String(startTime.getMinutes()).padStart(2, "0");
          this.scheduleInfo.starting_time = `${hours}:${minutes}`;

          this.scheduleInfo.summary = res.data.schedule.summary;
          this.scheduleInfo.location = res.data.schedule.location;
          this.scheduleInfo.description = res.data.schedule.description;

          this.openModal();
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

    updateSchedule(scheduleId) {
      this.scheduleInfo
        .put("/app/update-interview-schedule/" + scheduleId)
        .then((res) => {
          this.s(res.data.message);
          this.getResults();
          this.closeModal();
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

    deleteSchedule(proposeId, companyId, scheduleId) {
      axios
        .delete(
          "/app/delete-interview-schedule/" +
            proposeId +
            "/" +
            companyId +
            "/" +
            scheduleId
        )
        .then((res) => {
          this.s(res.data.message);
          this.getResults();
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

    showConfirmation(proposeId, companyId, scheduleId) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to cancel the schedule!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, do it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteSchedule(proposeId, companyId, scheduleId);
        }
      });
    },
    attendanceUpdate(scheduleId) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to confirm the attendance!",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, do it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.attendanceStatusUpdate(scheduleId);
        }
      });
    },
    attendanceStatusUpdate(scheduleId) {
      axios
        .put("app/schedule/attend/status/update/" + scheduleId)
        .then((res) => {
          this.s(res.data.message);
          this.getResults();
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },
    openModal() {
      this.modalShow = true;
    },

    closeModal() {
      this.modalShow = false;
    },

    badgeClass(status) {
      return {
        "badge badge-primary": status === "Pending",
        "badge badge-info": status === "Short listed",
        "badge badge-warning": status === "Interviewing",
        "badge badge-success": status === "Selected",
        "badge badge-danger": status === "Rejected",
        "badge badge-secondary": status === "Cancel interview",
      };
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },
  },

  watch: {
    "search.search_data": function () {
      this.SearchData();
    },
    "search.paginate": function () {
      this.paginateChange();
    },
  },
};
</script>
<style scoped>
p {
  margin: 0;
}
</style>
