<template>
  <div v-if="permission.access">
    <div class="row" v-if="applicationData.length != 0">
      <div class="col-md-12">
        <b-card title="Application List" no-body>
          <b-card-body>
            <b-row class="justify-content-between">
              <b-col
                cols="12"
                md="1"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label class="mr-1">Show</label>
                <Select
                  @on-change="paginateChange"
                  v-model="search.paginate"
                  :clearable="false"
                >
                  <Option :value="10"> 10 </Option>
                  <Option :value="30"> 30 </Option>
                  <Option :value="50"> 50 </Option>
                  <Option :value="80"> 80 </Option>
                  <Option :value="100"> 100 </Option>
                </Select>
              </b-col>
            </b-row>
            <div class="row">
              <div
                class="col-xl-2"
                v-for="(item, index) in selected"
                :key="index"
              >
                <div class="profile-wrapper center">
                  <div class="profile-body mt-2 d-flex justify-content-between">
                    <div class="IMAGE">
                      <img
                        class="d-block"
                        :src="item.photo || getImage(60, 60)"
                        alt="dd"
                        width="60"
                        height="60"
                      />
                    </div>

                    <div class="profile-details">
                      <h5
                        style="margin-top: 15px; font-size: 12px; color: black"
                      >
                        {{ item.name }}
                      </h5>
                      <p class="description" style="line-height: 16px">
                        {{ item.designation }}
                      </p>
                    </div>
                    <p
                      style="
                        margin-top: 10px;
                        margin-right: 15px;
                        cursor: pointer;
                      "
                      @click="removeSelectedItem(index)"
                    >
                      X
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-xl-2">
                <b-button
                  variant="primary"
                  type="submit"
                  @click="Propose(2)"
                  :disabled="form.busy"
                  class="ml-1 mt-3"
                  v-if="selected.length"
                >
                  Propose
                </b-button>
              </div>
            </div>
          </b-card-body>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="text-center">Sl</th>
                  <th class="text-center">Photo</th>
                  <th>Name</th>
                  <th class="text-center">Qualification</th>
                  <th>Status</th>
                  <th>Applied At</th>
                  <th class="text-center">Action</th>
                  <th>Apply</th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-if="applicationData.data.length"
                  v-for="(applicant, index) in applicationData.data"
                  :key="index"
                >
                  <td>
                    {{ applicationData.meta.from + index }}
                  </td>
                  <td class="text-center">
                    <img
                      :src="
                        applicant.photo || getImage(75, 75)
                      "
                      alt="dd"  
                      width="75"
                      height="75"
                      style="border-radius: 50%"
                    />
                  </td>
                  <td>
                    <p>{{ applicant.candidate }}</p>
                    <p><b>Phone: </b>{{ applicant.phone }}</p>
                    <p><b>Email: </b>{{ applicant.email }}</p>
                  </td>
                  <td class="text-center">
                    {{ applicant.designation }}
                    <p>
                      <b>Experience: </b
                      >{{
                        applicant.experiences != ""
                          ? applicant.experiences + " Years"
                          : "Fresher"
                      }}
                    </p>
                  </td>
                  <td>
                    <span
                      class="badge"
                      :class="{
                        'badge-success': applicant.status == 'Applied',
                        'badge-primary': applicant.status == 'Sent',
                        'badge-info': applicant.status == 'Selected',
                      }"
                    >
                      {{
                        applicant.status == "Sent"
                          ? "Proposed"
                          : applicant.status
                      }}
                    </span>
                  </td>
                  <td>{{ applicant.created_at }}</td>
                  <td class="text-center">
                    <button
                      class="btn btn-primary btn-sm"
                      @click="
                        questionModal(
                          applicant.question_answers,
                          applicant.cover_letter
                        )
                      "
                    >
                      <feather-icon icon="HelpCircleIcon" size="16" />
                    </button>
                    <router-link
                      class="btn btn-success btn-sm"
                      style="margin-left: 5px"
                      :to="'/profile/' + applicant.candidate_id"
                    >
                      <feather-icon icon="EyeIcon" size="16" />
                    </router-link>
                  </td>
                  <td>
                    <button
                      class="btn btn-sm btn-primary"
                      @click="
                        info(
                          applicant.id,
                          applicant.candidate,
                          applicant.photo,
                          applicant.designation,
                          applicant.status
                        )
                      "
                      :disabled="
                        applicant.status != 'Applied' ||
                        isButtonDisabled(applicant.id)
                      "
                    >
                      Select
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Modal v-model="modal6" footer-hide title="Question and cover letter">
            <h5 class="mt-0">Cover letter</h5>
            <p>
              {{ cover_letter }}
            </p>
            <hr style="margin-bottom: 2px !important" />
            <hr style="margin-top: 0 !important" />
            <h5 class="my-1">Questions and answers</h5>
            <div
              class="text-left mb-1"
              v-if="applicantQuestionAnswers.length"
              v-for="(question, num) in applicantQuestionAnswers"
              :key="num"
            >
              <h5 class="mt-0">Q :{{ question.question }} ?</h5>
              <p>A :{{ question.answer }}</p>
              <hr />
            </div>
          </Modal>
          <b-card-body>
            <div
              class="d-flex justify-content-between align-items-center"
              v-if="applicationData.length != 0"
            >
              <span class="text-muted"
                >Showing {{ applicationData.meta.from }} to
                {{ applicationData.meta.to }} of
                {{ applicationData.meta.total }} entries</span
              >
              <pagination
                :data="applicationData"
                @pagination-change-page="getResults"
                align="right"
                :show-disabled="true"
                :limit="5"
              ></pagination>
            </div>
          </b-card-body>
        </b-card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BRow,
  BButton,
  BCol,
  BForm,
  BFormCheckboxGroup,
} from "bootstrap-vue";
export default {
  data() {
    return {
      id: this.$route.params.id,
      form: new Form({
        id: [],
        status: null,
      }),
      applicationData: {},
      modal6: false,
      loading: true,
      applicantQuestionAnswers: [],
      cover_letter: null,
      selected: [],
      search: {
        page: 1,
        paginate: 10,
      },
    };
  },
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BButton,
    BFormCheckboxGroup,
  },
  mounted() {
    this.getResults();
  },
  methods: {
    getResults() {
      axios
        .get("/app/company/job/applications/" + this.id)
        .then((res) => {
          this.applicationData = res.data;
        })
        .catch((e) => {
          this.e(e.response.data.error);
        });
    },
    Propose(status) {
      this.form.id = [];
      this.selected.forEach((item) => {
        this.form.id.push(item.id);
      });
      this.form.status = status;
      this.form
        .post("/app/company/job/propose/" + this.id)
        .then((res) => {
          this.s(res.data.message);
          this.selected = [];
          this.getResults();
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

    isButtonDisabled(candidateId) {
      return this.selected.some((item) => item.id === candidateId);
    },

    removeSelectedItem(index) {
      this.selected.splice(index, 1);
    },

    info(id, name, photo, designation, status) {
      const isDuplicate = this.selected.some((item) => item.id === id);
      if (!isDuplicate) {
        this.selected.push({ id, name, photo, designation, status });
      }
    },

    questionModal(applicantQuestionAnswers, cover_letter) {
      this.modal6 = true;
      this.applicantQuestionAnswers = applicantQuestionAnswers;
      this.cover_letter = cover_letter;
    },

    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },
  },
  computed: {
    permission() {
      return this.$store.getters.getPermissions.jobs;
    },
  },
};
</script>
<style scoped>
h5 {
  color: #333333;
  text-transform: capitalize;
  margin-top: 10px;
}
p {
  font-size: 16px;
  font-weight: 400;
  color: #5c5c5c;
  margin-bottom: 0;
}
ul li {
  margin-top: 10px;
  color: #5c5c5c;
  margin-left: 40px;
}
.summary p {
  margin-left: 0;
}

.profile-body {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 20px;
}

.profile-body img {
  margin: 10px 15px;
  border: 3px solid white;
  border-radius: 25%;
}
.description {
  font-size: 12px;
}
.profile-details h5 {
  margin-bottom: 0.1em;
}
</style>
