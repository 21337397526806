<template>
  <div>
    <div class="row" v-if="jobData.length != 0">
      <div class="col-xl-12">
        <b-tabs>
          <b-tab title="Job" active>
            <div
              class="col-xl-12 m-auto"
              v-for="(job, index) in jobData"
              :key="index"
            >
              <div class="row">
                <div class="col-xl-9">
                  <div class="card px-2">
                    <div
                      class="card-header mt-2 d-flex justify-content-between w-100"
                    >
                      <div class="header text-left">
                        <h4 style="color: #2b882f">{{ job.title }}</h4>
                        <p
                          style="
                            margin-left: 0 !important;
                            margin-bottom: 5px;
                            font-size: 18px;
                          "
                        >
                          <b>{{ job.company_name }}</b>
                        </p>
                      </div>
                      <div
                        class="div"
                        v-if="job.company_logo != 'Not Available'"
                      >
                        <img :src="job.company_logo" alt="image" />
                      </div>
                    </div>
                    <div class="card-body">
                      <h5 style="margin-top: 15px">Job Length</h5>
                      <p class="mb-1">{{ job.length }}</p>
                      <div v-html="job.summary"></div>

                      <h5 v-if="job.questions != ''">Questions</h5>
                      <ul v-if="job.questions != ''">
                        <li v-for="(question, sym) in job.questions" :key="sym">
                          {{ question.question }} ?
                        </li>
                      </ul>
                      <h5>Skills Requirements</h5>
                      <ul>
                        <li v-for="(skill, num) in job.skills" :key="num">
                          {{ skill.name }} ({{ skill.type }})
                        </li>
                      </ul>
                      <h5>Job Location</h5>
                      <p>
                        {{ job.address }},{{ job.upazilla }},{{
                          job.district
                        }},{{ job.division }}
                      </p>

                      <h5 v-if="job.estimated_start_date != null">
                        Estimated starting date
                      </h5>
                      <p v-if="job.estimated_start_date != null">
                        {{ job.estimated_start_date }}
                      </p>
                      <div class="div text-right">
                        <h5>Created By</h5>
                        <p>{{ job.created_by }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3">
                  <div class="card">
                    <div class="card-header" style="background-color: #37474f">
                      <h5 class="text-white mt-0 mb-0">Job Summary</h5>
                    </div>
                    <div class="card-body summary border">
                      <p class="mt-2">
                        <b>Published on:</b> {{ job.created_at }}
                      </p>
                      <p>
                        <b>Vacancy: {{ job.vacancy }}</b>
                      </p>
                      <p><b>Seniority: </b> {{ job.seniority }}</p>
                      <p><b>Employment Status: </b>{{ job.commitment }}</p>
                      <p><b>Workplace: </b>{{ job.workplace }}</p>
                      <p>
                        <b>Experience: </b>{{ job.required_experience }} Years
                      </p>
                      <p>
                        <b>Job Location: </b> {{ job.address }},{{
                          job.upazilla
                        }},{{ job.district }},{{ job.division }}
                      </p>
                      <p>
                        <b>Salary: </b>{{ job.negotiable }} ({{
                          job.salary_min
                        }}
                        - {{ job.salary_max }} BDT)
                      </p>
                      <p><b>Deadline:</b> {{ job.deadline }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div></b-tab
          >
          <b-tab title="Application">
            <Applications />
          </b-tab>
          <b-tab title="Matched candidate">
            <MatchedCandidate />
          </b-tab>
          <b-tab title="Schedule manage">
            <ScheduleManage />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import { BTabs, BTab } from "bootstrap-vue";
import Applications from "./Applications.vue";
import MatchedCandidate from "./MatchedCandidate.vue";
import ScheduleManage from "../company/partials/ScheduleManage.vue";
export default {
  components: {
    BTabs,
    BTab,
    Applications,
    MatchedCandidate,
    ScheduleManage,
  },
  data() {
    return {
      id: this.$route.params.id,
      jobData: {},
    };
  },
  mounted() {
    this.getJob();
  },
  methods: {
    getJob() {
      axios
        .get("/app/get/company/job/" + this.id)
        .then((res) => {
          this.jobData = res.data;
        })
        .catch((e) => {
          this.e(e.response.data.error);
        });
    },
  },
};
</script>
<style scoped>
h5 {
  color: #333333;
  text-transform: capitalize;
  margin-top: 25px;
}
p {
  font-size: 16px;
  margin-left: 25px;
  font-weight: 400;
  color: #5c5c5c;
}
ul li {
  margin-top: 10px;
  color: #5c5c5c;
  margin-left: 40px;
}
.summary p {
  margin-left: 0;
}
</style>
